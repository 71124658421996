<template>
  <validation-observer
      ref="saveFormRef"
      #default="{invalid}"
  >
    <b-modal
        id="credentialTypeSaveModal"
        body-class="position-static"
        centered
        :title="isCreated ? 'Thêm mới dữ liệu' : 'Cập nhật dữ lệu'"
        no-close-on-backdrop
        @show="onShow"
        @hidden="onHide"
    >
      <b-form>
        <b-form-group label-for="description">
          <template v-slot:label>
            Mô tả kiểu văn bằng/chứng chỉ <span class="text-danger">*</span>
          </template>
          <validation-provider
              #default="{ errors }"
              name="Mô tả kiểu văn bằng/chứng chỉ"
              rules="required"
          >
            <b-form-input
                id="description"
                v-model="targetItem.description"
                placeholder="Mô tả kiểu văn bằng/chứng chỉ"
                :state="getElementState(errors)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label-for="type">
          <template v-slot:label>
            Phân loại <span class="text-danger">*</span>
          </template>
          <validation-provider
              #default="{ errors }"
              name="Phân loại kiểu văn bằng/chứng chỉ"
              rules="required"
          >
            <v-select
                v-model="targetItem.type"
                :options="types"
                :reduce="option => option.value"
                :placeholder="`Chọn phân loại`"
            >
              >

            </v-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label-for="status">
          <template v-slot:label>
            Trạng thái <span class="text-danger">*</span>
          </template>
          <validation-provider
              #default="{ errors }"
              name="Trạng thái"
              rules="required"
          >
            <v-select
                v-model="targetItem.status"
                :options="statusOptions"
                :reduce="option => option.value"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-center">
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              :disabled="invalid"
              @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" />Lưu lại
            </span>
          </b-button>

          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              @click="$bvModal.hide('credentialTypeSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" />Hủy
            </span>

          </b-button>
        </div>
      </template>

    </b-modal>
  </validation-observer>
</template>
<script>
import Ripple from 'vue-ripple-directive'
import { Flag } from '@/const/flag'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BModal, BOverlay,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import vSelect from 'vue-select'
import { required } from '@validations'
import { getUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'CredentialTypeSave',
  components: {
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    item: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      targetItem: {
        description: '',
        type: '',
        status: 1,
      },
      required,
    }
  },

  computed: {
    ...mapGetters({
      statuses: 'credentialType/statuses',
      types: 'credentialType/types',

    }),
    statusOptions() {
      return this.statuses
    },
    isCreated() {
      return !this.item
    },
  },
  watch: {
  },
  created() {
  },
  mounted() {
  },
  methods: {
    ...mapActions({
      createCredentialType: 'credentialType/createCredentialType',
      updateCredentialType: 'credentialType/updateCredentialType',
    }),
    async onSave(type = null) {
      try {
        const response = this.isCreated ? await this.createCredentialType(this.targetItem) : await this.updateCredentialType(this.targetItem)
        if (response) {
          const { isSuccessful, message } = response
          if (isSuccessful) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            if (type === 'hide') {
              this.$bvModal.hide('credentialTypeSaveModal')
            }
            this.$emit('succeed')
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
        // eslint-disable-next-line no-empty
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
    onShow() {
      if (this.item) {
        this.targetItem = { ...this.item }
      }
    },
    onHide() {
      this.targetItem = {
        description: '',
        type: '',
        status: 1,
      }
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
  },
}
</script>

<style scoped lang="scss">

</style>
